import React, {useState, useEffect} from "react";
import { navigate } from "@reach/router"
import { Form } from "react-bootstrap";
import { useStaticQuery, graphql } from "gatsby"
import Select from 'react-select';
import { startCase } from "lodash";

import SelectDropDown from "gatsby-theme-starberry-lomondgroup/src/components/FilterBox/Sections/SelectDropDown"
import SearchResultInputBox from 'gatsby-theme-starberry-lomondgroup/src/components/SearchResult/SearchResultBox';
import {GetPrice, PropertySearchUrl, getPriceUrl, searchStateToUrl} from "gatsby-theme-starberry-lomondgroup/src/common/utils/property_utils";
import {defaultArea, index_name, defaultArea_Newhomes_Slug} from "gatsby-theme-starberry-lomondgroup/src/common/constant";
import {PageLinks} from "../../../common/page-links";

const BannerSearchJohnshepherd = (props) => {

    var menu_slug = props.GQLPage?.menu?.slug;

    var formFields = {
        query: '',
        range: {
            price: {
                min: null,
                max: null
            },
            bedroom: {
                min: null
            }
        },
        menu: {
            building: null
        }
    }


    const[search_type_val, setSearchType] = useState("lettings");
    const[min_price_val, setMinPrice] = useState(0);
    const[max_price_val, setMaxPrice] = useState(0);
    const[bed_val, setBedroom] = useState('');
    const[query, setQuery] = useState("");
    const[property_type_val, setPropertyType] = useState("");
    const[department, setDepartment] = useState("residential");

    const data = useStaticQuery(graphql`
        query BannerSearchHardisty{
            glstrapi {
                propertySearch {
                    search_type {
                        name
                        value
                        id
                    }
                    sales_price_list {
                      price:Price
                    }
                    rent_price_list {
                      price
                    }
                }
            }
        }
    `)

    var search_type_list = data.glstrapi.propertySearch.search_type;
    var sales_price_list = data.glstrapi.propertySearch.sales_price_list;
    var rent_price_list = data.glstrapi.propertySearch.rent_price_list;
    var price_list = (search_type_val === "lettings" || search_type_val === "students") ? rent_price_list : sales_price_list;

    var collection_page = props?.GQLPage?.menu?.slug === "collection" ? true : false;
    var student_page = props?.GQLPage?.menu?.slug === "student-hub" ? true : false;

    useEffect(()=>{
        if(menu_slug === "new-homes" || menu_slug === "new-developments" || menu_slug === "land-and-new-homes"){
            if(search_type_list.length > 0){
                var filter_list = search_type_list.filter(item => item.name === "New Homes")
                var replace_str = filter_list[0].name.replace(/ /g,'-').toLowerCase()+"-";

                //setDepartment(filter_list[0].name.toLowerCase())
                setSearchType(filter_list[0].value.replace(replace_str,''))
            }

        } else if(menu_slug === "commercial"){
            setDepartment("commercial")
            setSearchType("sales")
        } else{
            if(menu_slug?.includes("rent") || menu_slug?.includes("landlords")){
                setSearchType("lettings")
            } else if(search_type_list.length > 0){
                var replace_str = search_type_list[0].name.replace(/ /g,'-').toLowerCase()+"-";
                                
                //setDepartment(search_type_list[0].name.toLowerCase())
                setSearchType(search_type_list[0].value.replace(replace_str,''))
            }
        }
        
    },[search_type_list, menu_slug])

    /* ========= search types ======= */
    var search_type_options = [];
    search_type_list.map((item, index) => {
        if(menu_slug === "home"){
            if(item.value === "sales" || item.value === "lettings"){
                search_type_options.push({ label: item.name, value: item.value })
            }
        } else{
            search_type_options.push({ label: item.name, value: item.value })
        }
    })


    /* ==== Min Price === */
    var min_price = [{ value: '', label: 'Min Price' }];
    price_list.map((item, index) => {
        if (parseInt(max_price_val) > 0) {
            if (parseInt(item.price) < parseInt(max_price_val)) {
                min_price.push({ value: parseInt(item.price), label: (search_type_val === "lettings" || search_type_val === "students") ? GetPrice(parseInt(item.price)) + " pcm" : GetPrice(parseInt(item.price))})
            }
        } else {
            min_price.push({ value: parseInt(item.price), label: (search_type_val === "lettings" || search_type_val === "students") ? GetPrice(parseInt(item.price)) + " pcm" : GetPrice(parseInt(item.price)) })
        }

    })

    /* ==== Max Price === */
    var max_price = [{ value: '', label: 'Max Price' }];
    price_list.slice(0).map((item, index) => {
        if (parseInt(min_price_val) > 0) {
            if (parseInt(item.price) > parseInt(min_price_val)) {
                max_price.push({ value: parseInt(item.price), label: (search_type_val === "lettings" || search_type_val === "students") ? GetPrice(parseInt(item.price)) + " pcm" : GetPrice(parseInt(item.price))  })
            }

        } else {
            max_price.push({ value: parseInt(item.price), label: (search_type_val === "lettings" || search_type_val === "students") ?  GetPrice(parseInt(item.price)) + " pcm" : GetPrice(parseInt(item.price))  })
        }

    })

    /* ==== Bedrroms === */
    var bed = [{ value: '', label: 'Bedrooms' }, { value: 0, label: "Studio" }];
    for(var i = 1; i<=10; i++){
        bed.push({ value: i, label: i+"+" })
    }


    /* ======== Property Type ======= */
    // var filter_property_type = department === "residential" ? property_type_list.filter(item => item.department === "residential" || item.department === "both") : property_type_list.filter(item => item.department === "commercial" || item.department === "both");

    // var property_type = [{ value: '', label: 'Property Type' }];
    // filter_property_type.map((item, index) => {
    //     property_type.push({ value: item.value, label: item.label })
    // })

    const handleChange = (e) => {
        setQuery(e.target.value)
    }

    const handleChangeSearchType = (e) => {
        //console.log("event_tar", e)
        var replace_str = e.label.replace(/ /g,'-').toLowerCase()+"-";
        
        if(e.label.includes("Commercial")){
            setSearchType(e.value.replace("commercial-",''))
            setDepartment("commercial")
        } else if(e.label.includes("Residential")){
            setSearchType(e.value.replace("residential-",''))
            setDepartment("residential")
        } else{
            setSearchType(e.value.replace(replace_str,''))
            setDepartment("residential")
        }
    }

    const onFilterSubmit = (searchState) => {
        var path = ""
        //console.log("department ==>", department, search_type_val)

        if(search_type_val === "newhomes"){
            path = PropertySearchUrl("residential", "sales")
        } else{
            path = PropertySearchUrl(department, search_type_val)
        }


        if(search_type_val === "newhomes"){
            //navigate(`/${PageLinks.new_homes}/in-${defaultArea_Newhomes_Slug}`)
            navigate(searchStateToUrl(index_name, searchState, path), {state: {new_homes:true}})
        } 
        else{
            navigate(searchStateToUrl(index_name, searchState, path))
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        formFields.query = query ? query :  defaultArea;
        formFields.range.price['min'] = min_price_val;
        formFields.range.price['max'] = max_price_val;
        formFields.range.bedroom['min'] = bed_val;
        formFields.menu['building'] = property_type_val;
        //formFields.includes = include;

        onFilterSubmit(formFields)
    }

    //console.log("GQLPage", props.GQLPage?.menu?.slug)
    
    var search_type_placeholder = "Buy";

    if(department === "residential"){
        search_type_placeholder = search_type_val === "sales" ? "Buy" : search_type_val === "newhomes" ? "New Homes" : "Rent";
    } 
    if(department === "commercial"){
        search_type_placeholder = search_type_val === "sales" ? "Commercial Sales" : "Commercial Lettings";
    }

    //console.log("search_type_val", department, search_type_val);


    return(
        <div className={`filter-box  ${department} ${collection_page || student_page ? "collection_search" : ""}`}>
            <Form action="javascript:;" method="post" onSubmit={handleSubmit}>
                
                <div className="select-option dropdown search_type first">
                    {/* <SelectDropDown 
                        options={search_type_options} 
                        placeholder={search_type_list.length > 0 ? search_type_list[0].name : "Rent" } 
                        className="react-select"
                        classNamePrefix="react-select"
                        handleChange={handleChangeSearchType}
                        //menuIsOpen={true}
                    /> */}
                    <Select options={search_type_options}
                        placeholder={search_type_placeholder} 
                        className={"react-select"}
                        classNamePrefix="react-select"
                        onChange={handleChangeSearchType}
                        isSearchable={false}
                    />

                </div>
                

                <div className="input-box">
                {/* <input
                    type="text"
                    placeholder="Property Location"
                    className="location_input" 
                    onChange={handleChange}
                /> */}
                <SearchResultInputBox areaVal={query} placeHolder={`Property Location`} department={department} handleChange={setQuery} className="location_input"/>
                </div>
                <div className="select-option dropdown d-none d-lg-flex">
                    <SelectDropDown 
                        options={min_price} 
                        placeholder="Min Price" 
                        className="react-select"
                        classNamePrefix="react-select"
                        handleChange={setMinPrice}
                        selectedValue={parseInt(min_price_val) > 0 ? GetPrice(min_price_val) : ""}
                    />
                </div>
                <div className="select-option dropdown d-none d-lg-flex">
                    <SelectDropDown 
                        options={max_price} 
                        placeholder="Max Price" 
                        className="react-select"
                        classNamePrefix="react-select"
                        handleChange={setMaxPrice}
                        selectedValue={parseInt(max_price_val) > 0 ? GetPrice(max_price_val) : ""}
                    />
                </div>

                {department !== "commercial" &&
                <div className="select-option dropdown d-none d-lg-flex bedroom">
                    <SelectDropDown 
                        options={bed} 
                        placeholder="Bedrooms" 
                        className="react-select"
                        classNamePrefix="react-select"
                        handleChange={setBedroom}
                        selectedValue={parseInt(bed_val) === 0 ? "Studio" : parseInt(bed_val) > 0 ? bed_val+"+" : ""}
                    />
                </div>
                }

                <button className="search-icon" type="submit">
                    <i className="icon-search"></i>
                </button>
            </Form>
        </div>
    )
}
export default BannerSearchJohnshepherd;